//@ts-nocheck
import React, { useState, useEffect, FC } from 'react';
import './WeeksPicker.scss';

interface IDatePicker {
	setCodeLifeNumber: (totalDays: number) => void;
}

const DatePicker: FC<IDatePicker> = ({ setCodeLifeNumber }) => {
	const currentYear = new Date().getFullYear();
	const currentMonth = new Date().getMonth() + 1; // місяці в JavaScript починаються з 0
	const currentDay = new Date().getDate();
	const [selectedYears, setSelectedYears] = useState(''); // Спочатку значення порожнє
	const [selectedMonth, setSelectedMonth] = useState(''); // Спочатку значення порожнє
	const [selectedWeek, setSelectedWeek] = useState(''); // Спочатку значення порожнє
	const [selectedDay, setSelectedDay] = useState(''); // Спочатку значення порожнє
	const [totalDays, setTotalDays] = useState(0);

	// Масиви для опцій вибору
	const yearOptions = Array.from({ length: 10 }, (_, i) => i + 1); // Від 1 до 10 років
	const months = Array.from({ length: 12 }, (_, i) => i + 1);
	const weeks = Array.from({ length: 4 }, (_, i) => i + 1);
	const days = Array.from({ length: 31 }, (_, i) => i + 1);

	// Функція для підрахунку загальної кількості днів
	const calculateTotalDays = () => {
		let totalDaysCalculated = 0;

		// Спочатку додаємо кількість днів на основі років
		if (selectedYears !== '') {
			totalDaysCalculated += selectedYears * 365;
		}

		// Якщо місяць обрано, додаємо дні на основі кількості днів у місяці
		if (selectedMonth !== '') {
			for (let month = 1; month <= selectedMonth; month++) {
				const daysInMonth = new Date(currentYear, month, 0).getDate();
				totalDaysCalculated += daysInMonth;
			}
		}

		// Якщо тиждень обрано, додаємо дні з урахуванням тижнів (1 тиждень = 7 днів)
		if (selectedWeek !== '') {
			totalDaysCalculated += selectedWeek * 7;
		}

		// Якщо день обрано, додаємо дні
		if (selectedDay !== '') {
			totalDaysCalculated += selectedDay;
		}

		setTotalDays(totalDaysCalculated);
	};

	// Викликаємо обчислення кількості днів після зміни будь-якого з вибраних значень
	useEffect(() => {
		calculateTotalDays();
	}, [selectedYears, selectedMonth, selectedWeek, selectedDay]);

	useEffect(() => {
		setCodeLifeNumber(totalDays);
	}, [totalDays]);

	return (
		<div className="date-picker">
			<div className="date-picker__select">
				<label>Years:</label>
				<select
					value={selectedYears}
					onChange={(e) => setSelectedYears(parseInt(e.target.value) || '')}
				>
					<option value="">Select year</option>
					{yearOptions.map((year) => (
						<option key={year} value={year}>
							{`${year} ${year === 1 ? 'year' : 'years'}`}
						</option>
					))}
				</select>
			</div>

			<div className="date-picker__select">
				<label>Month:</label>
				<select
					value={selectedMonth}
					onChange={(e) => setSelectedMonth(parseInt(e.target.value) || '')}
				>
					<option value="">Select month</option>
					{months.map((month) => (
						<option key={month} value={month}>
							{month}
						</option>
					))}
				</select>
			</div>

			<div className="date-picker__select">
				<label>Week:</label>
				<select
					value={selectedWeek}
					onChange={(e) => setSelectedWeek(parseInt(e.target.value) || '')}
				>
					<option value="">Select week</option>
					{weeks.map((week) => (
						<option key={week} value={week}>
							{week}
						</option>
					))}
				</select>
			</div>

			<div className="date-picker__select">
				<label>Day:</label>
				<select
					value={selectedDay}
					onChange={(e) => setSelectedDay(parseInt(e.target.value) || '')}
				>
					<option value="">Select day</option>
					{days.map((day) => (
						<option key={day} value={day}>
							{day}
						</option>
					))}
				</select>
			</div>

			<div className="date-picker__result">
				<strong>Total Days:</strong> {totalDays} days
			</div>
		</div>
	);
};

export default DatePicker;
