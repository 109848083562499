import { recommended, verified } from 'CONST/cardName';
import { ChangeEvent, FC, KeyboardEvent, useEffect } from 'react';
import { Select } from 'ui/Select/Select';
import './Main.scss';
import { DeleteSvg } from 'assets/svg-icon';
import { SelectList } from 'ui/SelectList/SelectList';
import { IBot, ICategory, IShows } from 'redux/reducers/ai-chat-bot/types';
import { useParams } from 'react-router-dom';
import { IEditVerifidOrRecommend } from 'pages/Dashboard/EditAiChatBot/EditAiChatBot';
import { Textarea } from 'ui/Textarea/Textarea';
import { WatchButton } from 'ui/WatchButton/WatchButton';
import classNames from 'classnames';

interface IProps {
	onModal: () => void;
	categoryList: Array<ICategory> | null;
	categoryListTwo: Array<ICategory> | null;
	botInfo: null | IBot;
	onChangeInput: (event: ChangeEvent) => void;
	onEditBotInputs: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onSelect: (value: string) => void;
	onSelectTwo: (value: string, key?: string | number, id?: string | number) => void;
	onEditVerifidOrRecommend: (value: IEditVerifidOrRecommend) => void;
	name: string;
	userName: string;
	description: string;
	pandaDescription: string;
	systemPrompt: string;
	userPromt: string;
	setDefaultTextInput: () => void;
	onSave: () => void;
	shows?: IShows | undefined;
}

export interface IParams {
	[key: string]: string | any;
}

export const Main: FC<IProps> = ({
	onChangeInput,
	onEditBotInputs,
	onModal,
	onEditVerifidOrRecommend,
	setDefaultTextInput,
	onSelect,
	onSelectTwo,
	onSave,
	categoryList,
	categoryListTwo,
	botInfo,
	name,
	userName,
	description,
	pandaDescription,
	systemPrompt,
	userPromt,
	shows,
}) => {
	const params = useParams<IParams>();

	const changeRecommended = (channel_id: number, recommend: boolean) => {
		onEditVerifidOrRecommend({ recommended: recommend });
	};

	const changeVerified = (channel_id: number, verify: boolean) => {
		onEditVerifidOrRecommend({ verified: verify });
	};

	useEffect(() => {
		setDefaultTextInput();
	}, []);

	return (
		<div className="edit-main">
			<div className="edit-main__header">
				<div className="edit-main__select">
					<Select data={verified} defaultType={botInfo?.verified} upgradeChannel={changeVerified} />
					<Select
						data={recommended}
						defaultType={botInfo?.recommended}
						upgradeChannel={changeRecommended}
					/>
				</div>
				<button onClick={onModal} type="button" className="edit-main__btn-remove">
					<DeleteSvg />
					<span>Delete Ai Bot</span>
				</button>
			</div>
			<div className="edit-main__body">
				<div className="edit-main__input-wrp">
					<div className="edit-main__label">Name</div>
					<input
						title="Press Enter to make changes"
						name="name"
						value={name}
						placeholder="Enter name"
						type="text"
						className="edit-main__input"
						onChange={onChangeInput}
						onKeyDown={onEditBotInputs}
					/>
					<span className={classNames('edit-main__count', { red: 30 - name.length === 0 })}>
						{30 - name.length}
					</span>
				</div>
				<div className="edit-main__input-wrp">
					<div className="edit-main__label">Username</div>
					<input
						title="Press Enter to make changes"
						name="username"
						value={userName}
						placeholder="Enter username"
						type="text"
						className="edit-main__input"
						onChange={onChangeInput}
						onKeyDown={onEditBotInputs}
					/>
					<span className={classNames('edit-main__count', { red: 30 - userName.length === 0 })}>
						{30 - userName.length}
					</span>
				</div>
				<div>
					<div style={{ position: 'relative' }} className="edit-main__input-wrp">
						<div className="edit-main__label">Description</div>
						<Textarea
							name="description"
							value={description}
							placeholder="Enter description"
							className="edit-main__input"
							handleTextarea={onChangeInput}
							// onKeyDownInput={onEditBotInputs}
						/>
						<div className="desc-total">{128 - description.length}</div>
					</div>
					<div style={{ position: 'relative' }} className="edit-main__input-wrp">
						<div style={{ marginTop: '11px' }} className="edit-main__label">
							Panda Description
						</div>
						<Textarea
							name="panda_description"
							value={pandaDescription}
							placeholder="Enter description"
							className="edit-main__input"
							handleTextarea={onChangeInput}
							// onKeyDownInput={onEditBotInputs}
						/>
						<div className="desc-total">{210 - pandaDescription.length}</div>
					</div>

					<div style={{ position: 'relative' }} className="edit-main__input-wrp">
						<div style={{ marginTop: '11px' }} className="edit-main__label">
							System prompt
						</div>
						<Textarea
							name="system_prompt"
							value={systemPrompt}
							placeholder="Enter system prompt"
							className="edit-main__input"
							handleTextarea={onChangeInput}
							// onKeyDownInput={onEditBotInputs}
						/>
						<div className="desc-total">{210 - systemPrompt.length}</div>
					</div>
				</div>

				<div className="edit-main__input-wrp">
					<div>
						<div className="edit-main__label">Category</div>
						<SelectList
							defaultSelect={botInfo?.category}
							onSelect={onSelect}
							placeholder="Enter category"
							data={categoryList?.map((item) => item.category) || ['Empty']}
						/>
					</div>
					{shows?.text_bot_config_bot_command && !shows?.photo_bot_config_bot_command ? (
						<>
							<div style={{ marginTop: '10px' }}>
								<div className="edit-main__label">AI model</div>
								<SelectList
									defaultSelect={botInfo?.ai_model}
									onSelect={onSelectTwo}
									keyRequest="ai_model"
									placeholder="Enter AI model"
									logo={
										categoryListTwo
											?.filter((item) => item.mtype === 1)
											.map((item) => item.logo) || ['Empty']
									}
									data={
										categoryListTwo
											?.filter((item) => item.mtype === 1)
											.map((item) => item.category) || ['Empty']
									}
								/>
							</div>
							<div style={{ marginTop: '10px' }}>
								<div className="edit-main__label">AI model low</div>
								<SelectList
									defaultSelect={botInfo?.ai_model_low}
									onSelect={onSelectTwo}
									keyRequest="ai_model_low"
									placeholder="Enter AI model"
									logo={
										categoryListTwo
											?.filter((item) => item.mtype === 1)
											.map((item) => item.logo) || ['Empty']
									}
									data={
										categoryListTwo
											?.filter((item) => item.mtype === 1)
											.map((item) => item.category) || ['Empty']
									}
								/>
							</div>
						</>
					) : null}
					{shows?.document_bot_config_bot_command ? (
						<>
							<div style={{ marginTop: '10px' }}>
								<div className="edit-main__label">AI model</div>
								<SelectList
									defaultSelect={botInfo?.ai_model}
									onSelect={onSelectTwo}
									keyRequest="ai_model"
									placeholder="Enter AI model"
									logo={
										categoryListTwo
											?.filter((item) => item.mtype === 3)
											.map((item) => item.logo) || ['Empty']
									}
									data={
										categoryListTwo
											?.filter((item) => item.mtype === 3)
											.map((item) => item.category) || ['Empty']
									}
								/>
							</div>
							<div style={{ marginTop: '10px' }}>
								<div className="edit-main__label">AI model low</div>
								<SelectList
									defaultSelect={botInfo?.ai_model_low}
									onSelect={onSelectTwo}
									keyRequest="ai_model_low"
									placeholder="Enter AI model"
									logo={
										categoryListTwo
											?.filter((item) => item.mtype === 3)
											.map((item) => item.logo) || ['Empty']
									}
									data={
										categoryListTwo
											?.filter((item) => item.mtype === 3)
											.map((item) => item.category) || ['Empty']
									}
								/>
							</div>
						</>
					) : null}
					{shows?.photo_bot_config_bot_command && !shows?.text_bot_config_bot_command ? (
						<>
							<div style={{ marginTop: '10px' }}>
								<div className="edit-main__label">AI model</div>
								<SelectList
									defaultSelect={botInfo?.ai_model}
									onSelect={onSelectTwo}
									keyRequest="ai_model"
									placeholder="Enter AI model"
									logo={
										categoryListTwo
											?.filter((item) => item.mtype === 2)
											.map((item) => item.logo) || ['Empty']
									}
									data={
										categoryListTwo
											?.filter((item) => item.mtype === 2)
											.map((item) => item.category) || ['Empty']
									}
								/>
							</div>
							<div style={{ marginTop: '10px' }}>
								<div className="edit-main__label">AI model low</div>
								<SelectList
									defaultSelect={botInfo?.ai_model_low}
									onSelect={onSelectTwo}
									keyRequest="ai_model_low"
									placeholder="Enter AI model"
									logo={
										categoryListTwo
											?.filter((item) => item.mtype === 2)
											.map((item) => item.logo) || ['Empty']
									}
									data={
										categoryListTwo
											?.filter((item) => item.mtype === 2)
											.map((item) => item.category) || ['Empty']
									}
								/>
							</div>
						</>
					) : null}

					{shows?.photo_bot_config_bot_command && shows?.text_bot_config_bot_command ? (
						<>
							<div style={{ marginTop: '10px' }}>
								<div className="edit-main__label">AI model</div>
								<SelectList
									defaultSelect={botInfo?.ai_model}
									onSelect={onSelectTwo}
									keyRequest="ai_model"
									placeholder="Enter AI model"
									logo={
										categoryListTwo
											?.filter((item) => item.mtype !== 3)
											.map((item) => item.logo) || ['Empty']
									}
									data={
										categoryListTwo
											?.filter((item) => item.mtype !== 3)
											.map((item) => item.category) || ['Empty']
									}
								/>
							</div>
							<div style={{ marginTop: '10px' }}>
								<div className="edit-main__label">AI model</div>
								<SelectList
									defaultSelect={botInfo?.ai_model_low}
									onSelect={onSelectTwo}
									keyRequest="ai_model_low"
									placeholder="Enter AI model"
									logo={
										categoryListTwo
											?.filter((item) => item.mtype !== 3)
											.map((item) => item.logo) || ['Empty']
									}
									data={
										categoryListTwo
											?.filter((item) => item.mtype !== 3)
											.map((item) => item.category) || ['Empty']
									}
								/>
							</div>
						</>
					) : null}
					<div style={{ position: 'relative' }} className="edit-main__input-wrp">
						<div style={{ marginTop: '11px' }} className="edit-main__label">
							Bot behavior style
						</div>
						<Textarea
							name="user_prompt"
							value={userPromt}
							placeholder="Enter user promt"
							className="edit-main__input"
							handleTextarea={onChangeInput}
							// onKeyDownInput={onEditBotInputs}
						/>
						<div className="desc-total">{210 - userPromt.length}</div>
					</div>
				</div>
			</div>
			{description || pandaDescription || systemPrompt || userPromt ? (
				<WatchButton
					onSave={onSave}
					vars={[description, pandaDescription, userName, name, systemPrompt, userPromt]}
				/>
			) : null}
		</div>
	);
};
