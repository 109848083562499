import React, { ChangeEvent, useState, useEffect } from 'react';
import { DeleteSvg } from '../../assets/svg-icon';
import defaultImg from '../../assets/images/AI/Component_2.png';
import openAi from '../../assets/images/AI/open-ai.png';
import claude from '../../assets/images/AI/Claude.png';
import gemini from '../../assets/images/AI/gemini.png';
import llama from '../../assets/images/AI/Llama.png';
import leonardo from '../../assets/images/AI/leonardo.png';
import stabilityAi from '../../assets/images/AI/stabilityAi.png';
import dall from '../../assets/images/AI/dall-i.png';
import { useDispatch } from 'react-redux';
import { editAiModelRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { getNumberModel } from './SubTabsModel/AiModelForm';
interface IOption {
	value?: string;
	label?: string;
	img?: string;
}
const options: Array<IOption> = [
	{ value: '0', label: 'Default image', img: defaultImg },
	{ value: '1', label: 'OpenAI', img: openAi },
	{ value: '2', label: 'Claude', img: claude },
	{ value: '3', label: 'Gemini', img: gemini },
	{ value: '4', label: 'Llama', img: llama },
	{ value: '5', label: 'Leonardo', img: leonardo },
	{ value: '6', label: 'Stability AI', img: stabilityAi },
	{ value: '7', label: 'DALL-E', img: dall },
];

interface IProps {
	toggleAccordion: (id: number) => void;
	isOpen: number;
	setOpen: (num: number) => void;
	id: number;
	mtype: number;
	aimodel: string;
	hash: string;
	logo: number | null;
}

const getOnType = (key: number) => {
	switch (key) {
		case 1:
			return 'Text model';
		case 2:
			return 'Image model';
		case 3:
			return 'Doc model';
	}
};

export const Acardion: React.FC<IProps> = ({
	mtype,
	aimodel,
	hash,
	logo,
	id,
	toggleAccordion,
	isOpen,
	setOpen,
}) => {
	const [aimodelNew, setAimodelNew] = useState<string>(aimodel);
	const [hashNew, setHashNew] = useState<string>(hash);
	const [mtypeNew, setMtypeNew] = useState<number>(mtype);
	const [logoNew, setLogoNew] = useState<number | null>(logo);
	const [selectedOption, setSelectedOption] = useState<IOption>({
		label: options[logo || 0].label,
	});
	const [openList, setOpenList] = useState(false);
	const [isSaveEnabled, setIsSaveEnabled] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		// Check if any field is changed
		const hasChanges =
			aimodelNew !== aimodel || hashNew !== hash || mtypeNew !== mtype || logoNew !== logo;
		setIsSaveEnabled(hasChanges);
	}, [aimodelNew, hashNew, mtypeNew, logoNew, aimodel, hash, mtype, logo]);

	const toggleOptions = () => {
		setOpenList(!openList);
	};

	const onChange = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;
		name === 'hash' ? setHashNew(value) : setAimodelNew(value);
	};

	const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const { value, id } = event.target;
		console.log(value);
		id === 'logo'
			? setLogoNew(Number(getNumberModel(value.toLocaleLowerCase().replace(' ', '_'))))
			: setMtypeNew(Number(getNumberModel(value.toLocaleLowerCase().replace(' ', '_'))));
	};

	const handleOptionClick = (option: IOption) => {
		setSelectedOption(option);
		toggleOptions();
		setLogoNew(Number(option.value));
	};

	const onSave = () => {
		dispatch(
			editAiModelRequest({
				aimodel: aimodelNew,
				hash: hashNew,
				mtype: mtypeNew,
				id,
				logo: logoNew || undefined,
			}),
		);
	};

	const onReset = () => {
		setAimodelNew(aimodel);
		setHashNew(hash);
		setMtypeNew(mtype);
		setLogoNew(logo);
	};

	return (
		<div className="accordion">
			<div className="accordion-header" onClick={() => toggleAccordion(id)}>
				<span>{aimodel}</span>
				<span className={`chevron ${isOpen === id ? 'open' : ''}`}>▶</span>
			</div>
			<div className={`accordion-content ${isOpen === id ? 'open' : ''}`}>
				<div className="accordion-input-container">
					<button id={String(id) || ''} onClick={() => setOpen(id || 0)} type="button">
						<DeleteSvg />
						<span>Delete AI Model</span>
					</button>
					<div className="accordion-input-wrap">
						<span>Name</span>
						<input
							onChange={onChange}
							value={aimodelNew}
							type="text"
							name="aimodel"
							id={String(id) || ''}
						/>
					</div>
					<div className="accordion-input-wrap">
						<span>Logo</span>
						<div className="custom-select">
							<div className="selected-option" onClick={toggleOptions}>
								{selectedOption || logoNew ? (
									<div className="option-content">{selectedOption?.label}</div>
								) : (
									'Select an option'
								)}
							</div>
							<div className={`options ${openList ? 'open' : ''}`}>
								{options.map((option) => (
									<div
										key={option.value}
										className="option"
										onClick={() => handleOptionClick(option)}
									>
										<img src={option.img} alt={option.label} />
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="accordion-input-wrap">
						<span>Type</span>
						<select name="mtype" value={getOnType(mtypeNew)} onChange={handleSelectChange}>
							<option value="" disabled>
								{getOnType(mtypeNew) || 'Select an option'}
							</option>
							<option value="Text model">Text model</option>
							<option value="Image model">Image model</option>
							<option value="Doc model">Doc model</option>
						</select>
					</div>
					<div className="accordion-input-wrap">
						<span>Hash</span>
						<input onChange={onChange} value={hashNew} type="text" name="hash" id={String(id)} />
					</div>
				</div>
				<div className="accordion-buttons">
					<button onClick={onReset} className="cancel-button" type="button">
						Cancel
					</button>
					<button
						className="cancel-button"
						onClick={onSave}
						type="button"
						disabled={!isSaveEnabled}
					>
						Save
					</button>
				</div>
			</div>
		</div>
	);
};
