import { FC, useEffect } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import ErrorMsg from 'components/ErrorMsg';
import { useSelector, useDispatch } from 'react-redux';
import { getLoader } from 'redux/reducers/auth/selectors';
import { SmallLoader } from 'ui/SmallLoader';
import { SelectList } from 'ui/SelectList/SelectList';
import { aimodel, hash, mtype } from 'untils/formValidation';
import { IAiModelAdd } from 'redux/reducers/ai-chat-bot/types';
import { addAiModelRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { ETabsAiModels } from 'services/constants/lists-table';

interface IProps {
	onClose: () => void;
}

export const getNumberModel = (key: string) => {
	switch (key) {
		case 'text_model':
			return '1';
		case 'image_model':
			return '2';
		case 'doc_model':
			return '3';
	}
};

const onGetType = (num: number) => {
	if (num === 3) {
		return ETabsAiModels.DOCUMENT_AI;
	}

	if (num === 2) {
		return ETabsAiModels.IMAGE_AI;
	}

	return ETabsAiModels.TEXT_AI;
};

export const AiModelForm: FC<IProps> = ({ onClose }) => {
	const loader = useSelector(getLoader);
	const navigation = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();

	const initialValues = {
		aimodel: '',
		hash: '',
		mtype: 0,
	};

	const validationSchema = yup.object().shape({
		aimodel,
		hash,
		mtype,
	});

	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="authorization-form__body">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values: IAiModelAdd) => {
					dispatch(
						addAiModelRequest({
							navigation,
							param: onGetType(Number(values.mtype)),
							onClose,
							body: {
								aimodel: values.aimodel,
								hash: values.hash,
								mtype: Number(values.mtype),
							},
						}),
					);
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ values, errors, setFieldValue }) => {
					const onAddType = (value: string) => {
						console.log(value);
						setFieldValue('mtype', getNumberModel(value));
					};

					return (
						<Form>
							<div className="authorization-form">
								<p className="form-title">Add AI Model</p>
								<div className="authorization-form__body">
									<div className="input">
										<p className="input__name">Name</p>
										<div className="input-wrapper">
											<Field className="input-item" type="text" name="aimodel" placeholder=" " />
										</div>
										{errors?.aimodel ? <ErrorMsg msg={errors.aimodel} /> : null}
									</div>
									<div className="input">
										<p className="input__name">Type</p>
										<SelectList
											onSelect={onAddType}
											data={['Text model', 'Image model', 'Doc model']}
											placeholder=" "
										/>
										{errors?.mtype ? <ErrorMsg msg={errors.mtype} /> : null}
									</div>
									<div className="input">
										<p className="input__name">Hash</p>
										<div className="input-wrapper">
											<Field className="input-item" type="text" name="hash" placeholder=" " />
										</div>
										{errors?.hash ? <ErrorMsg msg={errors.hash} /> : null}
									</div>
									<div className="form-submit">
										<button
											disabled={!(values.hash && values.aimodel && values.mtype)}
											type="submit"
											className="button button--full-width"
										>
											{loader ? <SmallLoader /> : 'Create'}
										</button>
									</div>
									<div className="form-submit">
										<button
											onClick={onClose}
											type="button"
											className="button button--gray button--full-width"
										>
											{loader ? <SmallLoader /> : 'Cancel'}
										</button>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
