import classNames from 'classnames';
import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EAiChatBotTabs, ETabsAiModels } from 'services/constants/lists-table';

export const SubTabsModel: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs table-tabs--type2 tab-up">
			<Link
				to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.AI_MODELS}/${ETabsAiModels.TEXT_AI}`}
				className={classNames('table-tabs__item', {
					active: params.id === ETabsAiModels.TEXT_AI || !params.id,
				})}
			>
				Text AI
			</Link>

			<Link
				to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.AI_MODELS}/${ETabsAiModels.IMAGE_AI}`}
				className={classNames('table-tabs__item', {
					active: params.id === ETabsAiModels.IMAGE_AI,
				})}
			>
				Image AI
			</Link>

			<Link
				to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.AI_MODELS}/${ETabsAiModels.DOCUMENT_AI}`}
				className={classNames('table-tabs__item', {
					active: params.id === ETabsAiModels.DOCUMENT_AI,
				})}
			>
				Document AI
			</Link>
		</div>
	);
};
