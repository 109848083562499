import axios, { AxiosInstance } from 'axios';
import onError from './interceptor/onError';
import onSuccess from './interceptor/onSuccess';
import onSuccessMultipart from './interceptor/onSuccessMultipart';
import { onHttp } from 'untils/onHttp';

const http: AxiosInstance = axios.create({
	baseURL: onHttp(),
	withCredentials: false,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
});

// @ts-ignore
http.interceptors.request.use(onSuccess);
http.interceptors.response.use((response) => response, onError);

export { http };

const multipart: AxiosInstance = axios.create({
	baseURL: onHttp(),
	withCredentials: false,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'multipart/form-data',
	},
});
// @ts-ignore
multipart.interceptors.request.use(onSuccessMultipart);

export { multipart };
