import { FC } from 'react';
import { CornerRightSvg, CornerSvg } from 'assets/svg-icon';
import { createAvataar } from 'untils/createAvatar';
import { Avatar } from 'ui/Avatar/Avatar';
import { useDispatch } from 'react-redux';
import { getFileRequest } from 'redux/reducers/download/reducer';
import { endpoint } from 'services/endpoint';
import { Video } from 'ui/Video/Video';
import { Audio } from 'ui/Audio/Audio';
import { File } from 'ui/File/File';
import classNames from 'classnames';
import { wrapLinkWithAnchor } from 'untils/wrapLinkWithAnchor';
import parse from 'html-react-parser';
import { NUMDEF } from 'CONST/cardName';

interface IProps {
	chat: string;
	admin: boolean;
	hash: string | undefined;
	hashImage: string | undefined;
	username: string | undefined;
	type: string | undefined;
	mime_type?: string | undefined;
	file_name?: string | undefined;
}

export const Chat: FC<IProps> = ({
	chat,
	admin,
	hash,
	username,
	hashImage,
	type,
	mime_type,
	file_name,
}) => {
	const dispatch = useDispatch();

	const byType = () => {
		if (mime_type?.includes('document') || type?.includes('document')) {
			return '/storage/document';
		}

		if (mime_type?.includes('image') || type?.includes('image')) {
			return '/storage/photo';
		}

		if (mime_type?.includes('video') || type?.includes('video')) {
			return '/storage/video';
		}
	};

	const onDownload = () => {
		if (type === 'video') {
			dispatch(
				getFileRequest({
					endpoint: endpoint.utils.video(hashImage || ''),
				}),
			);
		}
		if (type === 'document') {
			dispatch(
				getFileRequest({
					endpoint: endpoint.utils.document(hashImage || ''),
				}),
			);
		}
		if (type === 'photo') {
			dispatch(
				getFileRequest({
					endpoint: endpoint.utils.photo(hashImage || ''),
				}),
			);
		}
	};

	const onTypeFile = () => {
		if (mime_type?.includes('video') || type?.includes('video')) {
			return (
				<div className="wrap-video">
					<div className="video">
						<div className="video__container">
							<Video type={mime_type} endpoint={byType()} hash={String(hashImage)} />
						</div>
					</div>
				</div>
			);
		} else if (
			mime_type?.includes('image') ||
			mime_type?.includes('photo') ||
			type?.includes('photo') ||
			type?.includes('image')
		) {
			return (
				<div className="photo-wrap pointer" onClick={onDownload}>
					<Avatar type={type} endpoint={byType()} hash={String(hashImage)} />
				</div>
			);
		} else if (mime_type?.includes('audio') || type?.includes('audio')) {
			return (
				<div className="wrap-video">
					<div className="video">
						<Audio type={mime_type} endpoint={byType()} hash={String(hashImage)} />
					</div>
				</div>
			);
		} else {
			return (
				<div className="wrap-video">
					<div className={classNames('video', { 'text-color-admin': admin })}>
						<File
							type={mime_type}
							endpoint={byType()}
							hash={String(hashImage)}
							file_name={file_name}
						/>
					</div>
				</div>
			);
		}
	};

	return admin ? (
		<div className="chat-list chat-list--right">
			<div
				className={classNames('chat-list__text-wrap chat-list__text-wrap--right', {
					'text-color-admin': admin,
				})}
			>
				<div className="chat-list__corner chat-list__corner--right">
					<CornerRightSvg />
				</div>
				{hashImage ? onTypeFile() : parse(wrapLinkWithAnchor(chat))}
			</div>
		</div>
	) : (
		<div className="chat-list">
			{hash && NUMDEF !== hash ? (
				<div className="chat-list-avatar">
					<Avatar hash={String(hash)} />
				</div>
			) : (
				<div className="avatar-custom chat-list__img">
					<span>{createAvataar(username || '')}</span>
				</div>
			)}
			<div className={classNames('chat-list__text-wrap', { 'text-color-admin': admin })}>
				<div className="chat-list__corner">
					<CornerSvg />
				</div>
				{hashImage || type ? onTypeFile() : parse(wrapLinkWithAnchor(chat))}
			</div>
		</div>
	);
};
