import { indexOf } from 'lodash';

interface IComments {
	discussion_hash: {
		value: number;
	};
}

export interface IContent {
	webpage: {
		display_url: string;
		url: string;
	};

	document: {
		dc_id: number;
		id: number;
		access_hash: number;
		mime_type: string;
		size2_INT32: number;
		size2_INT64: number;
		sizes: number;
		predicate_name: string;
		attributes: Array<{ file_name: string }>;
	};
	photo_FLAGPHOTO: {
		dc_id: number;
		id: number;
		access_hash: number;
		mime_type: string;
		size2_INT32: number;
		size2_INT64: number;
		sizes: Array<{
			size2: number;
		}>;
		predicate_name: string;
		attributes: Array<{ file_name: string }>;
	};
	predicate_name: string;
	media_access_hash: string;
	messageId: number;
	outMessage: {
		message: string;
	};
	peer: {
		access_hash: number;
	};
	input: string;
	admin_id: string | number;
	media: {
		photo_FLAGPHOTO: {
			access_hash: string | number;
			predicate_name: string;
		};
		document: {
			access_hash: number;
			mime_type: string;
			predicate_name: string;
			attributes: Array<{ file_name: string }>;
		};
	};
}

export const onJsonParse = (json: string) => {
	const object: IComments = JSON.parse(json);
	return object.discussion_hash.value;
};

export const onJsonParseContent = (jsonStr: string): IContent => {
	const result = JSON.parse(jsonStr, (key, value) => {
		if (typeof value === 'number' && !Number.isSafeInteger(value)) {
			//@ts-ignore
			const strBig = jsonStr.match(new RegExp(`(?:"${key}":)(.*?)(?:,)`))[1];
			return strBig;
		}
		return value;
	});

	return result;
};

const parseMinusNumber = (key: string, json: string) => {
	const findKey = json.slice(json.lastIndexOf(key));
	const number = findKey.slice(findKey.indexOf('-'), findKey.indexOf(','));
	return number;
};

export const onJsonParseBigin = (json: string): IContent => {
	const accessHashMap = new Map<number, string>();

	// Зберігаємо всі значення access_hash як рядки у мапі
	const regex = /"access_hash":\s*"?(-?\d+)"?/g;
	let match;
	while ((match = regex.exec(json)) !== null) {
		const originalValue = match[1];
		accessHashMap.set(Number(originalValue), originalValue);
	}

	function reviver(key: string, value: any) {
		// Перевіряємо, чи значення є числом і не є безпечним цілим числом
		if (typeof value === 'number' && !Number.isSafeInteger(value)) {
			// Повертаємо точне значення з мапи, якщо знайдено
			return accessHashMap.get(value) || value.toString();
		}
		return value;
	}

	const parsed = JSON.parse(json, reviver);
	return parsed;
};
