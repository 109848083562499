import React, { useEffect, useState } from 'react';
import { SubTabsModel } from './SubTabsModel/SubTabsModel';
import { AiModelsAcardion } from './AiModelsAcardion';
import { AiModelForm } from './SubTabsModel/AiModelForm';
import { useDispatch, useSelector } from 'react-redux';
import { getAiModelsRequest } from '../../redux/reducers/ai-chat-bot/reducer';
import { useParams } from 'react-router-dom';
import './AiModels.scss';
import { ETabsAiModels } from 'services/constants/lists-table';
import { getAiModelsSelector } from 'redux/reducers/ai-chat-bot/selectors';

export const AiModels: React.FC = () => {
	const [open, setOpen] = useState(false);
	const aiModelsList = useSelector(getAiModelsSelector);
	const params = useParams();

	const dispatch = useDispatch();

	const onClose = () => {
		setOpen(false);
	};

	const onGetType = () => {
		if (params?.id?.includes(ETabsAiModels.DOCUMENT_AI)) {
			return 3;
		}

		if (params?.id?.includes(ETabsAiModels.IMAGE_AI)) {
			return 2;
		}

		return 1;
	};

	useEffect(() => {
		dispatch(getAiModelsRequest(onGetType()));
	}, [params?.id]);

	return (
		<div className="ai-models">
			<div className="ai-models__container">
				<div className="ai-models__header">
					<div className="ai-models__tabs">
						<SubTabsModel />
					</div>
					<button onClick={() => setOpen(!open)} type="button" className="ai-models__add-model-btn">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
						>
							<path
								d="M14.25 9H3.75M9 14.25L9 3.75"
								stroke="#0A49A5"
								strokeWidth="1.5"
								strokeLinecap="round"
							/>
						</svg>
						Add model
					</button>
				</div>
				{aiModelsList && <AiModelsAcardion aiModelsList={aiModelsList} />}
			</div>
			{open && (
				<div className="popup-container">
					<AiModelForm onClose={onClose} />
				</div>
			)}
		</div>
	);
};
