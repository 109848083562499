/* eslint-disable @typescript-eslint/naming-convention */
import { ELengthName } from 'services/constants/lenght';
import * as yup from 'yup';
import { PHONE_NUMBER_REGEXP, POSTAL_CODE_REGEXP } from 'services/constants/regexp';
// import valid from 'card-validator';

// ==========================================:
export const email = yup
	.string()
	.trim()
	.required('Please, enter your email!')
	.email('Invalid email format');

export const field = yup
	.string()
	.trim()
	.required('Please, enter your email!')
	.email('Invalid email format');

// ==========================================:
export const password = yup
	.string()
	.required('Please, enter your password!')
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
		'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
	);

// ==========================================:
export const confirmPassword = yup
	.string()
	.required('Please, enter your confirm password!')
	.oneOf([yup.ref('password'), null], 'Passwords must match');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const new_password = yup
	.string()
	.required('Please, enter your confirm password!')
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
		'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
	);

// ==========================================:
export const confirm_password = yup
	.string()
	.required('Please, enter your confirm password!')
	.oneOf([yup.ref('new_password'), null], 'Passwords must match');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const old_password = yup
	.string()
	.required(String('Please, enter your old password!'))
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
		String(
			'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
		),
	)
	.max(25, String('Max characters must be not more than 25!'));

// ==========================================:
export const passwordEdit = yup
	.string()
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
		'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
	);

// ==========================================:
export const confirmPasswordEdit = yup
	.string()
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
		'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
	)
	.oneOf([yup.ref('password'), null], 'Passwords must match');

// ==========================================:
export const acceptTerms = yup
	.bool()
	.oneOf([true], 'You must agree to the terms & conditions and privacy policy!');

// ==========================================:
export const temporaryCode = yup.string().required('Please, enter your temporary code!');

// ==========================================:
export const cookiePolicy = yup.bool().oneOf([true], 'You must agree to  cookie policy!');

// ==========================================:
export const recaptcha = yup.string().required('Please verify that you are a human!');

// ==========================================:
export const swap = yup.string().required('Insufficient funds on the wallet');

// ==========================================:
export const fullName = yup
	.string()
	.trim()
	.matches(
		/^[a-zA-Z]+( [a-zA-Z]+)+$/,
		'Full name must be at least two words, do not contain numbers and special symbol!',
	)
	.max(60, 'Full name max length must be not more 60 characters!')
	.required('Please, enter your full name!');

export const name = yup
	.string()
	.matches(/^[a-zA-Z\s]+$/, 'There must be only letters of the English alphabet!')
	.min(ELengthName.MIN, `User name should be minimum ${ELengthName.MIN}`)
	.max(ELengthName.MAX, `User name should be maximum ${ELengthName.MAX}`)
	.required('Name is required');

export const surname = yup
	.string()
	.matches(/^[a-zA-Z\s]+$/, 'There must be only letters of the English alphabet!')
	.min(ELengthName.MIN, `User surname should be minimum ${ELengthName.MIN}`)
	.max(ELengthName.MAX, `User surname should be maximum ${ELengthName.MAX}`)
	.required('Surname name is required');

export const phone = yup
	.string()
	.matches(PHONE_NUMBER_REGEXP, String('Wrong phone number format'))
	.required('Phone is required');

export const description = yup
	.string()
	.nullable()
	.required('Description is required')
	.min(3, 'validation_length_title');

// export const number = yup
// 	.string()
// 	.test(
// 		'test-number', // this is used internally by yup
// 		'Credit Card number is invalid', // validation message
// 		(value) => valid.number(value).isValid,
// 	)
// 	.max(19)
// 	.required();
export const cvc = yup.string().label('CVV').min(3).max(4).required();
export const nameOnCard = yup.string().label('Name on card').required();
export const exp_month = yup
	.string()
	.typeError('Not a valid expiration date. Example: MM/YY')
	.max(5, 'Not a valid expiration date. Example: MM/YY')
	.matches(/([0-9]{2})\/([0-9]{2})/, 'Not a valid expiration date. Example: MM/YY')
	.required('Date is a required field');

export const address = yup.string().required('Address is a required field');
export const role = yup.string().required('Role is a required field');
export const amount = yup
	.number()
	.typeError('Amount must be a number type')
	.required('Amount is a required field');

export const title = yup.string().required('This field is required');
export const bedroom_number = yup
	.number()
	.typeError('Only a number can be entered')
	.required('This field is required');
export const bathroom_number = yup
	.number()
	.typeError('Only a number can be entered')
	.required('This field is required');
export const square_meters = yup
	.number()
	.typeError('Only a number can be entered')
	.required('This field is required');

export const date_listing = yup.date().required('Select a date');
export const date_launch = yup.date().required('Select a date');
export const date_token_unlock = yup.date().required('Select a date');
export const price = yup
	.number()
	.typeError('Only a number can be entered')
	.required('This field is required');
export const tokens_number = yup
	.number()
	.typeError('Only a number can be entered')
	.required('This field is required');

export const city = yup.string().required('This field is required');
export const country = yup.string().required('This field is required');
export const state = yup.string().required('This field is required');
export const type = yup.string().required('This field is required');
export const lattitude = yup
	.number()
	.typeError('Only a number can be entered')
	.required('This field is required');
export const longitude = yup
	.number()
	.typeError('Only a number can be entered')
	.required('This field is required');
export const street = yup.string().required('This field is required');
export const index = yup
	.string()
	.matches(POSTAL_CODE_REGEXP, 'wrong_format_postcode')
	.test('required', 'Post code is required', (value) => !!value);
export const date_expected_dividends = yup.date().required('Select a date');
export const expected_profit = yup
	.number()
	.typeError('Only a number can be entered')
	.required('This field is required');
export const dividends_period = yup.boolean();

export const validationSchema = yup.object().shape({
	title,
	bedroom_number,
	bathroom_number,
	square_meters,
	description,
	date_listing,
	date_launch,
	date_token_unlock,
	price,
	tokens_number,
	country,
	city,
	street,
	lattitude,
	longitude,
	index,
	date_expected_dividends,
	expected_profit,
	dividends_period,
	state,
	type,
});

export const buy_token = yup
	.number()
	.min(0.1)
	.max(99)
	.typeError('Buy token must be a number type')
	.required('Field is required');

export const sell_token = yup
	.number()
	.min(0.1)
	.max(99)
	.typeError('Sell token must be a number type')
	.required('Field is required');

export const convert = yup
	.number()
	.min(0.1)
	.max(99)
	.typeError('Convert must be a number type')
	.required('Field is required');

export const deposit = yup
	.number()
	.min(0.1)
	.max(99)
	.typeError('Deposit must be a number type')
	.required('Field is required');

export const withdrawal = yup
	.number()
	.min(0.1)
	.max(99)
	.typeError('Withdrawal must be a number type')
	.required('Field is required');

export const invest = yup
	.number()
	.min(0.1)
	.max(99)
	.typeError('Invest must be a number type')
	.required('Field is required');

export const validationFeeSchema = yup.object().shape({
	buy_token,
	sell_token,
	convert,
	deposit,
	withdrawal,
	invest,
});

export const aimodel = yup.string().required('Ai model is required');
export const hash = yup.string().required('Hash is required');
export const mtype = yup.string().required('Type is required');
