import { FC, useState } from 'react';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { IAiModelAdd } from 'redux/reducers/ai-chat-bot/types';
import { Acardion } from './Acardion';
import { useDispatch } from 'react-redux';
import { deleteAiModelRequest } from 'redux/reducers/ai-chat-bot/reducer';

interface IProps {
	aiModelsList: Array<IAiModelAdd>;
}

export const AiModelsAcardion: FC<IProps> = ({ aiModelsList }) => {
	const [isOpen, setIsOpen] = useState<number>(0);
	const [open, setOpen] = useState<number>(0);
	const [aiId, setAiId] = useState<number | null>(null);
	const dispatch = useDispatch();

	const getId = (id?: number) => {
		setOpen(0);
		id && setAiId(id);
	};

	const toggleAccordion = (id: number) => {
		setIsOpen(isOpen === id ? 0 : id);
	};

	const removeAIModel = () => {
		dispatch(deleteAiModelRequest(open));
		setOpen(0);
	};

	return (
		<div className="accordion-wrap">
			{aiModelsList.map((model) => (
				<Acardion
					toggleAccordion={toggleAccordion}
					isOpen={isOpen}
					setOpen={setOpen}
					key={model.id as number}
					id={model.id as number}
					aimodel={model.aimodel}
					hash={model.hash}
					mtype={model.mtype}
					logo={model.logo || null}
				/>
			))}
			{open ? (
				<PopUpWrapper
					value={String(open)}
					handleButton={removeAIModel}
					isOpenModal={getId}
					title="Are you sure you want to delete AI model?"
					btnName="Yes, I’m sure"
					disablet={open}
				/>
			) : null}
		</div>
	);
};
