import classNames from 'classnames';
import { ChangeEvent, FC, useState } from 'react';
import avatar from '../../assets/images/ava2.png';
import ava from '../../assets/images/ava.png';
import { PlusBlueSvg, PointsSvg } from 'assets/svg-icon';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDetails } from 'redux/reducers/users/selectors';
import moment from 'moment';
import { getNotesSelector } from 'redux/reducers/support/selectors';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { Textarea } from 'ui/Textarea/Textarea';
import { GET_PHOTO } from 'services/constants/env';

import { creareNoteRequest, editNoteRequest } from 'redux/reducers/support/reducer';
import { createAvataar } from 'untils/createAvatar';
import { getUserData } from 'redux/reducers/auth/selectors';
import { SupportMenu } from 'components/SupportMenu/SupportMenu';
import { INote } from 'redux/reducers/support/types';
import { Avatar } from 'ui/Avatar/Avatar';
import { Link } from 'react-router-dom';
import { ETabsUsersDetails } from 'services/constants/lists-table';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { NUMDEF } from 'CONST/cardName';

interface IProps {
	hideRight: boolean;
	openUser: number;
}

export const SidebarSupportRight: FC<IProps> = ({ hideRight, openUser }) => {
	document.querySelector('.sidebar-right__overflow')?.scrollTop;
	const userData = useSelector(getUserDetails);
	const admin = useSelector(getUserData);
	const notes = useSelector(getNotesSelector);
	const [hide, setHide] = useState(false);
	const [popup, setPopup] = useState<boolean>(false);
	const [popupName, setPopupName] = useState<string>('');
	const [text, setText] = useState<string>('');
	const [id, setIdNote] = useState<number>(0);
	const dispatch = useDispatch();

	const onHide = () => {
		setHide(!hide);
	};

	const isOpenModal = () => {
		setPopupName('Add note');
		setPopup(!popup);
	};

	const onHandleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLTextAreaElement;
		setText(value);
	};

	const onSubmit = () => {
		userData &&
			popupName === 'Add note' &&
			dispatch(
				creareNoteRequest({
					id,
					support_id: openUser,
					text,
					user_id: userData.data.id,
				}),
			);
		popupName !== 'Add note' &&
			userData &&
			dispatch(
				editNoteRequest({
					id,
					support_id: openUser,
					text,
					user_id: userData.data.id,
				}),
			);
		isOpenModal();
		setText('');
	};

	const onEdit = (note: INote) => {
		setPopupName('Edit note');
		setPopup(!popup);
		setText(note.text.String);
		setIdNote(note.id as number);
	};

	const onStatusUser = () => {
		if (userData?.data.blocked) {
			return 'blocked-users';
		}

		if (userData?.data.deleted) {
			return 'delete-users';
		}

		return 'registered';
	};

	return (
		<div
			className={classNames('sidebar-right', {
				'sidebar-right-heid': hideRight,
			})}
		>
			{!hideRight && (
				<>
					<div className="sidebar-right__photo-wrap">
						{userData?.data.photo_access_hash &&
						String(userData?.data.photo_access_hash) !== String(NUMDEF) ? (
							<Avatar hash={String(userData?.data.photo_access_hash)} />
						) : (
							<div className="avatar-custom">
								<span>{createAvataar(userData?.data.name || '')}</span>
							</div>
						)}
						<div className="sidebar-right__title">
							{`${userData?.data.first_name.slice(0, 16) || ' '} ${
								userData?.data.last_name.slice(0, 16) || ''
							}`}
						</div>
					</div>
					<div className="sidebar-right__content-wrap">
						<div className="sidebar-right__content-left">
							<div className="sidebar-right__text-gray">ID</div>
							<div className="sidebar-right__text-gray large-text">Type</div>
							<div className="sidebar-right__text-gray">Username</div>
							<div className="sidebar-right__text-gray">Email</div>
							<div className="sidebar-right__text-gray">Registration date</div>
							{hide ? (
								<>
									<div className="sidebar-right__text-gray">Last login</div>
									<div className="sidebar-right__text-gray">Register Country</div>
									<div className="sidebar-right__text-gray">IP country</div>
									<div className="sidebar-right__text-gray">Profile</div>
									<div className="sidebar-right__text-gray">Gender</div>
								</>
							) : null}
						</div>
						<div className="sidebar-right__content-right">
							<Link
								to={`${ELinksName.USER_DETAILS}/${userData?.data.id}/${
									onStatusUser() || 'all-channels'
								}/${String(ETabsUsersDetails.USER_DATA)}`}
								className="sidebar-right__text link-color"
							>
								{userData?.data.id || '-'}
							</Link>
							<div className="sidebar-right__text large-text">
								{userData?.data.account_type || '-'}
							</div>
							<Link
								to={`${ELinksName.USER_DETAILS}/${userData?.data.id}/${
									onStatusUser() || 'all-channels'
								}/${String(ETabsUsersDetails.USER_DATA)}`}
								className="sidebar-right__text link-color"
							>{`@${userData?.data.username || '-'}`}</Link>
							<div className="sidebar-right__text">{userData?.data.email || '-'}</div>
							<div className="sidebar-right__text">
								{moment(userData?.data.last_login).format('MMM DD, YYYY, LT') || '-'}
							</div>
							{hide ? (
								<>
									<div className="sidebar-right__text-gray">
										{moment(userData?.data.register_at).format('MMM DD, YYYY, LT') || '-'}
									</div>
									<div className="sidebar-right__text">{userData?.data.country_name || '-'}</div>
									<div className="sidebar-right__text">{userData?.data.ip_country || '-'}</div>
									<div className="sidebar-right__text large-text">
										{userData?.data.account_kind || '-'}
									</div>
									<div className="sidebar-right__text large-text">
										{userData?.data.gender || '-'}
									</div>
								</>
							) : null}
						</div>
					</div>
					{hide ? (
						<>
							<div className="sidebar-right__text-gray">User bio</div>
							<div className="sidebar-right__text">{userData?.data.bio || '-'}</div>
						</>
					) : null}
					<div onClick={onHide} className="sidebar-right__more">
						{hide ? 'Hide' : 'More...'}
					</div>
					<div className="sidebar-right__notes-wrap">
						<div className="sidebar-right__button-inner">
							<div className="sidebar-right__notes">
								Notes
								<span>{notes?.length || 0}</span>
							</div>
							<button onClick={isOpenModal} className="sidebar-right__btn">
								<span>
									<PlusBlueSvg />
								</span>
								<span>Add note</span>
							</button>
						</div>

						<div className="sidebar-right__overflow">
							{notes?.map((message) => (
								<div key={message.id} className="sidebar-right__sms-wrap">
									<div className="sidebar-right__ava">
										{admin?.otp_enabled ? (
											<Avatar hash={String(admin?.photo_access_hash)} />
										) : (
											<div className="avatar-custom">
												<span>{createAvataar(`${admin?.first_name} ${admin?.last_name}`)}</span>
											</div>
										)}
									</div>
									<div className="sidebar-right__sms-content">
										<div className="sidebar-right__wrap-name">
											<div className="sidebar-right__notes">{`${admin?.first_name} ${admin?.last_name}`}</div>
											<SupportMenu note={message} onEdit={onEdit} />
										</div>
										<div className="sidebar-right__text-gray">
											{moment(message.created_at).format('MMM DD, YYYY, LT') || '-'}
										</div>
										<div className="sidebar-right__text-gray sidebar-right__text-pading">
											{message.text.String}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</>
			)}
			{popup && (
				<PopUpWrapper
					handleButton={onSubmit}
					isOpenModal={isOpenModal}
					title={popupName}
					btnName={popupName === 'Add note' ? 'Save' : 'Send'}
					disablet={text}
				>
					<Textarea
						value={text}
						handleTextarea={onHandleInput}
						name="description"
						placeholder="Text"
					/>
				</PopUpWrapper>
			)}
		</div>
	);
};
