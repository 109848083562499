import { AiChatSvg } from 'assets/svg-icon';
import { NUMDEF } from 'CONST/cardName';
import { ChangeEvent, FC } from 'react';
import { Avatar } from 'ui/Avatar/Avatar';

export const CheckBoxThree: FC<{
	onChange?: (event: ChangeEvent) => void;
	title: string;
	subtitle?: string;
	status?: string;
	checked?: boolean;
	type?: string;
	name?: string;
	id?: string;
	photo?: string;
	checkBox?: string;
}> = ({ photo, title, name, subtitle, onChange, checked, type, id, checkBox }) => {
	return (
		<div className="checkbox-one checkbox--mb-0 checkbox-three">
			<label className="checkbox-one__label">
				{type ? (
					<input
						id={id}
						onChange={onChange}
						type={type}
						className="hidden"
						defaultChecked={checked}
						checked={checkBox === name}
						name={name}
					/>
				) : null}
				{type ? (
					<span className="checkbox-one__item">
						<span className="checkbox-one__item-icon small-full">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M3.68457 9.89476L8.16674 13.9474L16.3161 5.26318"
									stroke="white"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</span>
				) : null}
				<div className="checkbox-one__photo">
					{photo && String(photo) !== String(NUMDEF) ? (
						<Avatar hash={String(photo)} />
					) : (
						<AiChatSvg />
					)}
				</div>

				<div className="admin-info__checkbox">
					<span className={`checkbox-one__text ${title === 'Blocked' ? 'red' : ''}`}>{title}</span>
					<span className="checkbox-one__text gray">{subtitle}</span>
				</div>
			</label>
		</div>
	);
};
